.SIL_TutImg1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Fisch_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_TutImg2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Fisch_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_TutImg3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Fisch_wrong3.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild1_Eimer_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild1_Eimer_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild1_Eimer_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild2_Heft_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild2_Heft_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild2_Heft_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Birne_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Birne_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild3_Birne_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img4_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild4_Flasche_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img4_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild4_Flasche_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img4_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild4_Flasche_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img5_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild5_Dose_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img5_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild5_Dose_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img5_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild5_Dose_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img6_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild6_Teller_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img6_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild6_Teller_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img6_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild6_Teller_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img7_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild7_Loffel_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img7_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild7_Loffel_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img7_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild7_Loffel_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img8_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild8_Hose_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img8_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild8_Hose_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img8_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild8_Hose_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img9_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild9_Spinne_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img9_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild9_Spinne_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img9_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild9_Spinne_wrong2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img10_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild10_Apfel_korrekt.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img10_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild10_Apfel_wrong.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img10_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Bild10_Apfel_wrong2.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img11_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz11_Tasse_korrekt.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img11_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz11_Tasse_wrong.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img11_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz11_Tasse_wrong2.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img12_1{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz12_korrekt.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img12_2{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz12_wrong.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img12_3{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Satz12_wrong2.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}